.layer{
  position: fixed; left: 0; top: 0;
  width: 100vw; height: 100vh;
  z-index: 1000;

  >.layer-mask{
    width: 100vw;
    height: 100vh;
    position: fixed; left: 0; top: 0;
    z-index: -1;
    background: rgba(0,0,0,.8);
  }
}